const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

var targetNode = document.getElementById("navbarCollapse");
var prevClassState = targetNode.classList.contains("show");
var observer = new MutationObserver(function (mutations) {
  mutations.forEach(function (mutation) {
    if (mutation.attributeName == "class") {
      var currentClassState = mutation.target.classList.contains("show");

      if (prevClassState !== currentClassState) {
        prevClassState = currentClassState;

        if (currentClassState) {
          document.body.classList.add("overflow-hidden");
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      }
    }
  });
});
observer.observe(targetNode, { attributes: true });
